<template>
  <div>
    <iq-title class-name="mobileHeader">Размер буфера:</iq-title>
    <div class="settings__main-section">
      <div style="flex: 1">
        <el-input-number v-model="bufferSize" :min="1" :max="15" />
      </div>
    </div>
  </div>
</template>

<script>
import IqTitle from '@/UI/typography/IqTitle'

export default {
  name: 'BufferSize',
  components: { IqTitle },
  props: {
    currentTerminalSize: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    bufferSize: {
      get() {
        return this.currentTerminalSize || 1
      },
      set(newValue) {
        this.$emit('setBufferZoneSize', newValue)
      },
    },
  },
}
</script>

<style scoped></style>
