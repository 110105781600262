<template>
  <div>
    <iq-title class-name="mobileHeader">
      Можно подтверждать ТС за (в минутах):
    </iq-title>

    <div :class="['arrival', { focused: isFocused || isArrow }]">
      <span
        :class="['arrival__button mod-left', { disable: disableDecrease }]"
        @mouseenter="isArrow = true"
        @mouseleave="isArrow = false"
        @click="decrease"
      >
        <i class="el-icon-minus" />
      </span>

      <el-input-number
        v-model="prefixSize"
        :controls="false"
        class="arrival__input"
        @focus="isFocused = true"
        @blur="handleInput"
      />

      <span
        :class="['arrival__button mod-right', { disable: disableIncrease }]"
        @mouseenter="isArrow = true"
        @mouseleave="isArrow = false"
        @click="increase"
      >
        <i class="el-icon-plus" />
      </span>
    </div>
  </div>
</template>

<script>
import IqTitle from '@/UI/typography/IqTitle'

const ARRIVAL_INTERVALS = [0, 5, 10, 15, 30, 60]

export default {
  name: 'ArrivalPrefixSize',
  components: { IqTitle },
  props: {
    currentPrefixSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
      isArrow: false,
    }
  },
  computed: {
    prefixSize: {
      get() {
        return this.currentPrefixSize || 0
      },
      set(newValue) {
        this.$emit('setPrefixSize', newValue)
      },
    },
    currentIndex() {
      return ARRIVAL_INTERVALS.indexOf(this.prefixSize)
    },
    disableIncrease() {
      return this.currentIndex === ARRIVAL_INTERVALS.length - 1
    },
    disableDecrease() {
      return this.currentIndex === 0
    },
  },
  methods: {
    increase() {
      if (!this.disableIncrease) {
        this.prefixSize = ARRIVAL_INTERVALS[this.currentIndex + 1]
      }
    },
    decrease() {
      if (!this.disableDecrease) {
        this.prefixSize = ARRIVAL_INTERVALS[this.currentIndex - 1]
      }
    },
    handleInput() {
      if (ARRIVAL_INTERVALS.indexOf(this.prefixSize) === -1) {
        this.prefixSize = ARRIVAL_INTERVALS[0]
      }
      this.isFocused = false
    },
  },
}
</script>

<style lang="sass" scoped>
.arrival
  display: flex
  width: 284px
  border: 1px solid #DCDFE6
  border-radius: 4px
  &.focused
    border-color: #409EFF

  &__input :deep(.el-input__inner)
    width: 200px
    border: none !important
    border-radius: unset !important

  &__button
    flex-shrink: 0
    width: 41px
    height: 40px
    line-height: 40px
    text-align: center
    background: #F5F7FA
    color: #606266
    cursor: pointer
    font-size: 13px
    &:hover
      color: #409EFF
    &.disable
      color: #C0C4CC
      cursor: not-allowed
      &:hover
        color: #C0C4CC

    &.mod-left
      border-radius: 4px 0 0 4px
      border-right: 1px solid #DCDFE6
    &.mod-right
      border-radius: 0 4px 4px 0
      border-left: 1px solid #DCDFE6
</style>
