<template>
  <div v-loading="isLoading" class="settings container">
    <iq-title class-name="desktopHeader">
      Настройки для терминала: {{ currentTerminal }}
    </iq-title>
    <div class="settings__card container">
      <div class="settings__half-container">
        <div class="settings__main-section">
          <div style="flex: 1">
            <editor :content.sync="contentForEditor" />
            <div class="settings__btn-box">
              <iq-button color="second" @onClick="contentForEditor = ''">
                Очистить
              </iq-button>
            </div>
          </div>

          <el-card
            v-if="contentForEditor"
            class="settings__preview"
            v-html="contentForEditor"
          />
        </div>
      </div>

      <div class="settings__sizes">
        <require-inn
          :require-inn="requireInn"
          @setRequireInn="updateRequireInn"
        />
        <buffer-size
          v-if="isSuperAdmin || isAdmin"
          class="settings__sizes-item"
          :current-terminal-size="bufferSize"
          @setBufferZoneSize="updateBufferSize"
        />
        <limit-size
          v-if="isSuperAdmin && canCreateManualsTs"
          :current-limit-size="limitSize"
          :current-limit-state="limitState"
          @setLimitState="updateLimitState"
          @setLimitSize="updateLimitSize"
        />
        <arrival-prefix-size
          v-if="isSuperAdmin"
          class="m-t-20"
          :current-prefix-size="arrivalPrefixSize"
          @setPrefixSize="updatePrefixSize"
        />
      </div>
      <div class="settings__btn-box">
        <iq-button @onClick="setSettings">
          <Icon name="SaveIcon" />
          Сохранить
        </iq-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_UNLOAD_INFO,
  GET_IS_LOADING_SETTINGS,
  GET_UNLOAD_INFO,
  SET_NEW_SETTINGS,
} from './store/actions'
import {
  GET_IS_ALLOWED_MANUAL_TS,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
} from '@/store/actions'
import { isObject } from 'element-ui/src/utils/types'
import { mapActions, mapGetters } from 'vuex'
import ArrivalPrefixSize from '@/views/settings/components/ArrivalPrefixSize.vue'
import BufferSize from './components/BufferSize'
import Editor from './components/Editor.vue'
import Icon from '@/UI/icon/Icon'
import IqButton from '@/UI/buttons/IqButton'
import IqTitle from '@/UI/typography/IqTitle'
import LimitSize from './components/LimitSize'
import RequireInn from '@/views/settings/components/RequireInn.vue'

export default {
  name: 'Settings',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1200, initial-scale=1',
      },
    ],
  },
  components: {
    IqButton,
    IqTitle,
    Editor,
    Icon,
    BufferSize,
    LimitSize,
    RequireInn,
    ArrivalPrefixSize,
  },
  data() {
    return {
      contentForEditor: '',
      bufferSize: -1,
      limitSize: -1,
      arrivalPrefixSize: 0,
      limitState: false,
      requireInn: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalList: GET_TERMINAL_LIST,
      currentTerminalID: GET_TERMINAL_CURRENT_ID,
      getUnloadInfo: GET_UNLOAD_INFO,
      canCreateManualsTs: GET_IS_ALLOWED_MANUAL_TS,
      isLoading: GET_IS_LOADING_SETTINGS,
    }),

    currentTerminal() {
      if (this.terminalList.length > 0 && this.currentTerminalID) {
        return this.terminalList.filter(
          i => i.id === this.currentTerminalID,
        )[0]['name']
      }

      return ''
    },
  },
  watch: {
    getUnloadInfo: {
      immediate: true,
      handler() {
        if (isObject(this.getUnloadInfo)) {
          this.bufferSize = this.getUnloadInfo.buffer_size
          this.limitSize = this.getUnloadInfo.manuals_limit_size
          this.limitState = Boolean(this.getUnloadInfo.manuals_limit_enabled)
          this.contentForEditor = this.getUnloadInfo.unload_trash_text
          this.requireInn = Boolean(this.getUnloadInfo.require_inn)
          this.arrivalPrefixSize = this.getUnloadInfo.minutes_allowed_early
        }
      },
    },

    currentTerminalID: {
      immediate: true,
      handler() {
        this.fetchUnloadInfo()
      },
    },
  },
  methods: {
    ...mapActions({
      saveSettings: SET_NEW_SETTINGS,
      fetchUnloadInfo: FETCH_UNLOAD_INFO,
    }),
    updateBufferSize(data) {
      this.bufferSize = data
    },
    updateLimitState(state) {
      if (state) {
        this.limitState = true
        this.limitSize = 1
      } else {
        this.limitState = false
        this.limitSize = 0
      }
    },
    updateLimitSize(data) {
      this.limitSize = data
    },
    updatePrefixSize(data) {
      this.arrivalPrefixSize = data
    },
    updateRequireInn(data) {
      this.requireInn = data
    },
    setSettings() {
      const dataRequest = {
        id: this.currentTerminalID,
        unload_trash_text: this.contentForEditor,
        buffer_size: this.bufferSize,
        manuals_limit_enabled: Number(this.limitState),
        manuals_limit_size: this.limitSize,
        require_inn: Number(this.requireInn),
        minutes_allowed_early: this.arrivalPrefixSize,
      }

      this.saveSettings(dataRequest)
    },
  },
}
</script>

<style lang="sass">

.settings
  padding: 20px 0
  &__card
    background: $color-white
    border: $border-default
    box-sizing: border-box
    box-shadow: $box-shadow-default
    border-radius: 4px
    padding: 20px
  &__container
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
  &__main-section
    min-height: 40%
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    @media(max-width: 768px)
      width: 100%
      flex-direction: column
      align-items: center
  &__preview
    padding: 16px
    width: 320px
    margin-left: 24px
    @media(max-width: 768px)
      width: 100%
      margin-top: 32px
      margin-left: 0

  &__btn-box
    display: flex
    align-items: center
    margin-top: 24px
    flex-wrap: wrap
    button
      width: 220px

  &__sizes
    margin: 0 0 26px
    &-item
      margin-right: 20px
    .el-input-number
      width: 220px
</style>
