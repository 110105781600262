<template>
  <div class="limits__wrapper">
    <iq-title class-name="mobileHeader">Лимит ручных тайм слотов:</iq-title>
    <div>
      <div class="limits">
        <el-checkbox v-model="limitState">
          {{ limitState ? 'Включен' : 'Включить' }}
        </el-checkbox>
        <el-input-number
          v-if="limitState"
          v-model="limitSize"
          :min="1"
          :max="1000"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IqTitle from '@/UI/typography/IqTitle'

export default {
  name: 'LimitSize',
  components: { IqTitle },
  props: {
    currentLimitSize: {
      type: Number,
      default: 1,
    },
    currentLimitState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    limitSize: {
      get() {
        return this.currentLimitSize || 1
      },
      set(newVal) {
        this.$emit('setLimitSize', newVal)
      },
    },
    limitState: {
      get() {
        return this.currentLimitState
      },
      set(newVal) {
        this.$emit('setLimitState', newVal)
      },
    },
  },
}
</script>

<style lang="sass">
.limits
  .el-checkbox
    display: block
    width: fit-content
    margin-bottom: 15px
    .el-checkbox__inner
      width: 21px
      height: 21px
      &::after
        border: 3px solid #FFF
        border-left: 0
        border-top: 0
        height: 11px
        left: 7px
.limits__wrapper
  margin-top: 20px
</style>
